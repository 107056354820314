<template>
  <div>
    <PageMenu :breadcrumbs="breadcrumbs" :page="page">
      <template v-slot:toolbar>
        <b-button variant="outline-primary" size="sm" :to='{name: "products.packages"}' class="mr-1">
          Manage Packages
        </b-button>

        <b-button variant="primary" size="sm" :to='{name: "products.product.create"}'>
          <BIconPlus/> New Product
        </b-button>
      </template>
    </PageMenu>

    <div class="page-with-menu">

      <div v-if="!table.isLoading">
        <b-table class="bg-white" responsive hover :items="table.records" :fields="table.fields" show-empty>
          <template #empty>
            no records
          </template>
          <template #cell(name)="data">
            <b-link :to='{name: "products.product.edit", params: {productId: data.item.id}}'>{{data.item.name}}</b-link>
          </template>
          <template #cell(options)="data">
            <div class="text-right">
              <b-link :to='{name: "products.product.edit", params: {productId: data.item.id}}'><BIconPencil/></b-link>
            </div>
          </template>
        </b-table>
        <PaginationRouter v-if="table.pagination.totalPages > 1" :pagination="table.pagination" :linkGen="paginationRouter"/>
      </div>
      <b-spinner v-else label="Loading..." small></b-spinner>
    </div>
  </div>
</template>

<script>
import {BIconPencil,BIconPlus} from 'bootstrap-vue';
export default {
    components: {
      PageMenu: () => import('@/common/components/PageMenu'),
      PaginationRouter: () => import('@/common/components/Pagination.Router'),
      BIconPencil,BIconPlus
    },
    data(){
      return {
        page: {
            title: "Products",
        },
        breadcrumbs: [
            {
                title: "Dashboard",
                url: this.$router.resolve({name: "app.dashboard"}).href
            },
            {
                title: "Product Management"
            }
        ],
        table: {
          isLoading: false,
          records: [],
          pagination: {
            currentPageNo: 1
          },
          fields: [
            {
              key: 'name',
              label: "Name"
            },
            {
              key: 'amount',
              label: "Default Value",
              formatter: value => {
                return value !== null ? value : "-";
              }
            },
            {
              key: 'validity',
              label: "Validity"
            },
            {
              key: 'upgradable',
              label: "Upgradable",
              formatter: (value) => {
                return value ? 'yes' : 'no';
              }
            },
            {
              key: 'downgradable',
              label: "Downgradable",
              formatter: (value) => {
                return value ? 'yes' : 'no';
              }
            },
            {
              key: 'status',
              label: "Status"
            },
            {
              key: 'options',
              label: ""
            },
          ]
        }
      }
    },
    methods: {
      paginationRouter(pageNum){
        let query =  {};
        if(pageNum > 1){
            query.page = pageNum;
        }

        return this.$router.resolve({name: 'translations',query: query}).href;
      },

      getResults({pageNo},filters){
        this.table.isLoading = true;
        this.$api.get('products',{
          params: Object.assign({
            perPage: 50,
            page: pageNo,
          },filters || {})
        }).then(({data}) => {
          this.table.isLoading = false;
          this.table.records = data.records;
          this.table.pagination = data.pagination;
          this.page.title = `Products (${data.total})`;
        });
      },
    },
    mounted(){
      this.getResults({pageNo: 1});

      this.$emit('set-sidebar',{active: "products"});
    },

    watch: {
      "$route.query.page": function(pageNo){
        this.getResults({pageNo: pageNo});
      }
    },
};
</script>
